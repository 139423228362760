import React, { useState, Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar8.css'

const Navbar8 = (props) => {
  const [link5DropdownVisible, setLink5DropdownVisible] = useState(false)
  const [link5AccordionOpen, setLink5AccordionOpen] = useState(false)
  return (
    <header className="navbar8-container1">
      <header data-thq="thq-navbar" className="navbar8-navbar-interactive">
        <img
          alt={props.logoAlt}
          src={props.logoSrc}
          className="navbar8-image1"
        />
        <div data-thq="thq-navbar-nav" className="navbar8-desktop-menu">
          <nav className="navbar8-links1">
            <Link to="/" className="navbar8-link11">
              {props.link1 ?? (
                <Fragment>
                  <span className="navbar8-text15 thq-body-small thq-link">
                    Home
                  </span>
                </Fragment>
              )}
            </Link>
            <Link to="/Mentors" className="navbar8-link11">
              {props.link1 ?? (
                <Fragment>
                  <span className="navbar8-text15 thq-body-small thq-link">
                    Mentors
                  </span>
                </Fragment>
              )}
            </Link>
            <Link to="/Services" className="navbar8-link11">
              {props.link1 ?? (
                <Fragment>
                  <span className="navbar8-text15 thq-body-small thq-link">
                    Services
                  </span>
                </Fragment>
              )}
            </Link>
            <div
              onClick={() => setLink5DropdownVisible(!link5DropdownVisible)}
              className="navbar8-link4-dropdown-trigger"
            >
              <span>
                {props.link4 ?? (
                  <Fragment>
                    <span className="navbar8-text25 thq-body-small thq-link">
                      About us
                    </span>
                  </Fragment>
                )}
              </span>
              <div className="navbar8-icon-container1">
                {link5DropdownVisible && (
                  <div className="navbar8-container2">
                    <svg viewBox="0 0 1024 1024" className="navbar8-icon10">
                      <path d="M298 426h428l-214 214z"></path>
                    </svg>
                  </div>
                )}
                {!link5DropdownVisible && (
                  <div className="navbar8-container3">
                    <svg viewBox="0 0 1024 1024" className="navbar8-icon12">
                      <path d="M426 726v-428l214 214z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </nav>
          <div className="navbar8-buttons1">
            <Link to="/SignUp">
            <button className="navbar8-action11 thq-button-filled thq-button-animated">
              <span className="thq-body-small">
                {props.action1 ?? (
                  <Fragment>
                    <span className="navbar8-text24">Register</span>
                  </Fragment>
                )}
              </span>
            </button>
            </Link>
            <Link
              to="/Login"
              className="navbar8-action21 thq-button-outline thq-button-animated"
            >
              <span className="thq-body-small">
                {props.action2 ?? (
                  <Fragment>
                    <span className="navbar8-text14">Login</span>
                  </Fragment>
                )}
              </span>
            </Link>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar8-burger-menu" onClick={() => setLink5AccordionOpen(!link5AccordionOpen)}>
          <svg viewBox="0 0 1024 1024" className="navbar8-icon14">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        {link5AccordionOpen&&(
        <div data-thq="thq-mobile-menu" className="navbar8-mobile-menu">
          <div className="navbar8-nav">
            <div className="navbar8-top">
              <img
                alt={props.logoAlt}
                src={props.logoSrc}
                className="navbar8-logo"
              />
              <div data-thq="thq-close-menu" className="navbar8-close-menu" onClick={() => setLink5AccordionOpen(!link5AccordionOpen)}>
                <svg viewBox="0 0 1024 1024" className="navbar8-icon16">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbar8-links2">
                  <Link to="/">
                  <Fragment>
                    <span className="navbar8-text15 thq-body-small thq-link text-start">
                      Home
                    </span>
                  </Fragment>
                  </Link>
               
                 <Link to="/Mentors" className="navbar8-link11">
              {props.link1 ?? (
                <Fragment>
                  <span className="navbar8-text15 thq-body-small thq-link">
                    Mentors
                  </span>
                </Fragment>
              )}
            </Link>
            <Link to="/Services" className="navbar8-link11">
              {props.link1 ?? (
                <Fragment>
                  <span className="navbar8-text15 thq-body-small thq-link">
                    Services
                  </span>
                </Fragment>
              )}
            </Link>
             
              <div className="navbar8-link4-accordion">
                <div
                  onClick={() => setLink5DropdownVisible(!link5DropdownVisible)}
                  className="navbar8-trigger"
                >
                  <span>
                    {props.link4 ?? (
                      <Fragment>
                        <span className="navbar8-text25 thq-body-small thq-link">
                          About us
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <div className="navbar8-icon-container2">
                    {link5DropdownVisible && (
                      <div className="navbar8-container4">
                        <svg viewBox="0 0 1024 1024" className="navbar8-icon18">
                          <path d="M298 426h428l-214 214z"></path>
                        </svg>
                      </div>
                    )}
                    {!link5DropdownVisible && (
                      <div className="navbar8-container5">
                        <svg viewBox="0 0 1024 1024" className="navbar8-icon20">
                          <path d="M426 726v-428l214 214z"></path>
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                {link5DropdownVisible && (
                  <div className="navbar8-container6">
                 <Link to="/">

                      <div className="navbar8-menu-item1">
                        <img
                          alt=""
                          src={props.page1ImageSrc}
                          className="navbar8-page1-image1"
                        />
                        <div className="navbar8-content1">
                          <span>
                              <Fragment>
                                <Link to="/">
                                <span className="navbar8-text16 thq-body-large">
                                  Home
                                </span>
                                </Link>
                              </Fragment>
                          </span>
                          <span>
                              <Fragment>
                                <span className="navbar8-text21 thq-body-small">
                                  Welcome to FlarEdge - Your Gateway to Success!
                                </span>
                              </Fragment>
                            
                          </span>
                        </div>
                      </div>
                      </Link>
                      <a href = {props.linkUrlPage2}>
                      <div className="navbar8-menu-item2">
                        <img
                          alt={props.p2ImageAlt}
                          src={props.page2ImageSrc}
                          className="navbar8-page2-image1"
                        />
                        <div className="navbar8-content2">
                          <span>
                            {props.page2 ?? (
                              <Fragment>
                                <span className="navbar8-text19 thq-body-large">
                                 youtube
                                </span>
                              </Fragment>
                            )}
                          </span>
                          <span>
                            {props.page2Description ?? (
                              <Fragment>
                                <span className="navbar8-text18 thq-body-small">
                                Subscribe us on youtube for sessions

                                </span>
                              </Fragment>
                            )}
                          </span>
                        </div>
                      </div>
                      </a>
                      <a href={props.linkUrlPage3}>
                      <div className="navbar8-menu-item3">
                        <img
                          alt={props.p3ImageAlt}
                          src={props.page3ImageSrc}
                          className="navbar8-page3-image1"
                        />
                        <div className="navbar8-content3">
                          <span>
                            {props.page3 ?? (
                              <Fragment>
                                <span className="navbar8-text23 thq-body-large">
                                 Instagram
                                </span>
                              </Fragment>
                            )}
                          </span>
                          <span>
                            {props.page3Description ?? (
                              <Fragment>
                                <span className="navbar8-text20 thq-body-small">
                                Follow us on instagram to get updated !

                                </span>
                              </Fragment>
                            )}
                          </span>
                        </div>
                      </div>
                      </a>
                  </div>
                )}
              </div>
              <div className="navbar8-buttons2">
              <Link to="/Signup">
              <button className="thq-button-filled">
                <span>
                  {props.action1 ?? (
                    <Fragment>
                      <span className="navbar8-text24">Register</span>
                    </Fragment>
                  )}
                </span>
              </button>
              </Link>
              <Link to="/Login">
              <button className="thq-button-outline">
                <span>
                  {props.action2 ?? (
                    <Fragment>
                      <span className="navbar8-text14">Login</span>
                    </Fragment>
                  )}
                </span>
              </button>
              </Link>
            </div>
           
            </nav>
            
          </div>
          
        </div>
        )}
        {link5DropdownVisible && (
          <div className="navbar8-container7 thq-box-shadow">
            <div className="navbar8-link5-menu-list">
              <Link to="/">
              <a href='flaredge.online'>
                <div className="navbar8-menu-item5">
                  <img
                    alt={props.p1ImageAlt}
                    src={props.page1ImageSrc}
                    className="navbar8-page1-image2 thq-img-ratio-1-1"
                  />
                  <div className="navbar8-content5 text-start text-dark">
                    <span>
                      {props.page1 ?? (
                        <Fragment>
                          <span className="navbar8-text16 thq-body-large">
                            Home
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>
                      {props.page1Description ?? (
                        <Fragment>
                          <span className="navbar8-text21 thq-body-small">
                            Welcome to FlarEdge - Your Gateway to Success!
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </a></Link>
              <a href={props.linkUrlPage2}>
                <div className="navbar8-menu-item6">
                  <img
                    alt={props.p2ImageAlt}
                    src={props.page2ImageSrc}
                    className="navbar8-page2-image2 thq-img-ratio-1-1"
                  />
                  <div className="navbar8-content6 text-start text-dark">
                    <span>
                      {props.page2 ?? (
                        <Fragment>
                          <span className="navbar8-text19 thq-body-large">
                            Youtube
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>
                      {props.page2Description ?? (
                        <Fragment>
                          <span className="navbar8-text18 thq-body-small">
                          Subscribe us on youtube for sessions
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </a>
              <a href={props.linkUrlPage3}>
                <div className="navbar8-menu-item7">
                  <img
                    alt={props.p3ImageAlt}
                    src={props.page3ImageSrc}
                    className="navbar8-page3-image2 thq-img-ratio-1-1"
                  />
                  <div className="navbar8-content7 text-start text-dark">
                    <span>
                      {props.page3 ?? (
                        <Fragment>
                          <span className="navbar8-text23 thq-body-large">
                            Instagram
                          </span>
                        </Fragment>
                      )}
                    </span>
                    <span>
                      {props.page3Description ?? (
                        <Fragment>
                          <span className="navbar8-text20 thq-body-small">
                          Follow us on instagram to get updated !
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        )}
      </header>
      {link5DropdownVisible && (
        <div
          onClick={() => setLink5DropdownVisible(false)}
          className="navbar8-container8"
        ></div>
      )}
    </header>
  )
}

Navbar8.defaultProps = {
  page1ImageSrc:'/img/logo.png',
  page3ImageAlt: 'Image representing Services',
  page3ImageSrc:'https://images.unsplash.com/photo-1689852501130-e89d9e54aa41?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  page1ImageAlt: 'Image representing Home',
  logoSrc:'/img/logo.png',
  logoAlt: 'FlarEdge Logo',
  page2ImageSrc: 'https://images.unsplash.com/photo-1649180543887-158357417159?q=80&w=2062&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  page4ImageAlt: 'Image representing instagram',
  page2ImageAlt: 'Image representing utube',
  linkUrlPage1:"/",
  linkUrlPage2:"https://youtube.com/@flaredge.official?si=DNi8bgziFIVVnC5a",
  linkUrlPage3:"https://www.instagram.com/flaredge.official/"}

Navbar8.propTypes = {
  action2: PropTypes.element,
  link1: PropTypes.element,
  linkUrlPage4: PropTypes.string,
  page1: PropTypes.element,
  page4Description: PropTypes.element,
  page1ImageSrc: PropTypes.string,
  page3ImageAlt: PropTypes.string,
  page3ImageSrc: PropTypes.string,
  page2Description: PropTypes.element,
  linkUrlPage1: PropTypes.string,
  linkUrlPage3: PropTypes.string,
  page2: PropTypes.element,
  page1ImageAlt: PropTypes.string,
  page4ImageSrc: PropTypes.string,
  page3Description: PropTypes.element,
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  page2ImageSrc: PropTypes.string,
  page1Description: PropTypes.element,
  page4: PropTypes.element,
  page4ImageAlt: PropTypes.string,
  page3: PropTypes.element,
  link1Url: PropTypes.string,
  linkUrlPage2: PropTypes.string,
  action1: PropTypes.element,
  page2ImageAlt: PropTypes.string,
  link4: PropTypes.element,
}

export default Navbar8
