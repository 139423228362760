import Hero from '../components/Home_c/hero17';
import Navbar from '../components/navbar8';
import Feature from '../components/Home_c/features24';
import Cta from '../components/Home_c/cta26';
import Testimonials from '../components/Home_c/testimonial17';
import Footer from '../components/footer4';
import Contact3 from '../components/contact3';
import { Helmet } from "react-helmet";
import './Home.css';
const Home = ()  => {
  return (
    <div className="home">
      <Helmet>
        <title>FlarEdge | Personalized Mentorship for Students</title>
        <meta name="description" content="FlarEdge provides personalized mentorship and career advice." />
        <link rel="canonical" href="https://flaredge.online/" />
      </Helmet>
     
       <Navbar/>
       <Hero/>
       <Feature/>
       <Cta/>
       {/* <Feature2/> */}
       <Testimonials/>
       {/* <Step/> */}
       <Contact3/>
       <Footer/>
       
    </div>
  );
}

export default Home;
