import './card.css';

const Card = ({ profile }) => {
  
  return (
    <div>
      <div className="nft">
        <div className="main">
          <div className="top-section">
            <img 
              className="tokenImage" 
              src={profile.profileImage}
              alt="NFT" 
            />
            <ul className="social-icons">
             <li> {profile.instagram && <a href={profile.instagram} target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram "></i></a>}</li>
             <li>{profile.youtube && <a href={profile.youtube} target="_blank" rel="noopener noreferrer"><i className="bi bi-youtube "></i></a>}</li>
             <li>{profile.linkedin && <a href={profile.linkedin} target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin "></i></a>}</li>
            </ul>
            
          </div>
          
          <h2 className="text-start">{profile.username || ''}</h2>
          <h4 className='text-start'>{profile.profession||''}</h4>
        
          {/* Display each domain in a new line */}
          <div className="domains">
            {profile.domains && Array.isArray(profile.domains) && profile.domains.map((domain, index) => (
              <p className="text-start" key={index}>{domain}</p>
            ))}
          </div>
        
          

          <div className="cityname">
            <div className="city">
              <p>{profile.city}</p>
            </div>
            <div className="pincode">
              <p>{profile.pincode}</p>
            </div>
          </div>
          
          <hr />
        </div>
      </div>
    </div>
  );
};

export default Card;
