import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer4 from '../components/footer4';
import Navbar8 from '../components/navbar8';
import { db } from '../firebase';
import './Home.css';
import './member.css';
import { Helmet } from "react-helmet";


const Person = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const { userId } = useParams();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const q = query(collection(db, "Mentors"), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          console.log("No profile found!");
          alert("No profile found!");
        } else {
          querySnapshot.forEach((doc) => {
            setProfile(doc.data()); // Update state with the profile data

          });
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchProfile();
  }, [navigate, userId]);

  const handleUpdateClick = () => {
    navigate(`/update/${profile?.userId}`); // Navigate to the update page
  };


  if (loading) {
    return <div>Loading...</div>; // Show loading state while fetching
  }

  if (!profile) {
    return <div>No profile found!</div>; // Show message if profile is not found
  }
  console.log(profile.profileimage);


  return (
    
    <div className="person-page">
       <Helmet>
        <title>FlarEdge | Personalized Mentorship for Students</title>
        <meta name="description" content="FlarEdge provides personalized mentorship and career advice." />
        <link rel="canonical" href="https://flaredge.online/" />
        <link rel="icon" type="image/png" href="/img/new fevicon simple f.png"/>

      </Helmet>
      <Navbar8 />
<div className="container emp-profile">
            <form >
                <div className="row">
                    <div className="col-md-4">
                        {profile.profileimage? 
                         <div className="profile-img">
                            <img src={profile.profileimage} alt="profileimage that u set"/>  
                        </div>
                       : <div className="profile-img">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog" alt="default profile img"/>
                            
                        </div>}
                    </div>
                    <div className="col-md-6">
                        <div className="profile-head text-start">
                                    <h5>
                                        {profile.name}
                                    </h5>
                                    {profile.profession && <h6>
                                        {profile.profession}
                                    </h6>}
                                    {profile.description && <p>{profile.description}</p>}
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <h3 className="about" id="home-tab"  >About:</h3>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <input type='button' onClick={handleUpdateClick} className="profile-edit-btn" name="btnAddMore" value="Edit Profile"/>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-4">
                        <div className="profile-work text-start">
                            <p>WORK LINK</p>
                            <ul className="social-icons">
                            <li> {profile.instagram && <a href={profile.instagram} target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram "></i></a>}</li>
                            <li>{profile.youtube && <a href={profile.youtube} target="_blank" rel="noopener noreferrer"><i className="bi bi-youtube "></i></a>}</li>
                            <li>{profile.linkedin && <a href={profile.linkedin} target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin "></i></a>}</li>
                            </ul>
                            <p>SKILLS</p>
                           <div className="domains">
            {profile.domains && Array.isArray(profile.domains) && profile.domains.map((domain, index) => (
             <ul> <h6 className="text-start" key={index}>{domain}</h6></ul>
            ))}
          </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="tab-content profile-tab" id="myTabContent">
                            <div className="tab-pane fade show active text-start" id="home" role="tabpanel" aria-labelledby="home-tab">
                            {profile.username && <div className="row ">
                                            <div className="col-md-6">
                                                <label>User Id</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{profile.username}</p>
                                            </div>
                                        </div>}
                                        {profile.name && <div className="row">
                                            <div className="col-md-6">
                                                <label>Name</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{profile.name}</p>
                                            </div>
                                        </div>}
                                        {profile.profession&&<div className="row">
                                            <div className="col-md-6">
                                                <label>Profession</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{profile.profession}</p>
                                            </div>
                                        </div>}
                                        {profile.city && <div className="row">
                                            <div className="col-md-6">
                                                <label>City</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{profile.city}</p>
                                            </div>
                                        </div>}
                                        {profile.pincode && <div className="row">
                                            <div className="col-md-6">
                                                <label>Pin code</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{profile.pincode}</p>
                                            </div>
                                        </div>}
                                        {profile.fee && <div className="row">
                                            <div className="col-md-6">
                                                <label>apx. fee</label>
                                            </div>
                                            <div className="col-md-6">
                                                <p>{profile.fee}</p>
                                            </div>
                                        </div>}
                                       
                            </div>
                           
                        </div>
                    </div>
                </div>
            </form>           
        </div>
      <Footer4 />
    </div>
  );
};

export default Person;
