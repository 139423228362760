// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDsH5ynitgXLgHs-X3eQ1JFLhTlTA1Q6O4",
  authDomain: "flaredge.firebaseapp.com",
  projectId: "flaredge",
  storageBucket: "flaredge.appspot.com",
  messagingSenderId: "23956390147",
  appId: "1:23956390147:web:bc5922eb1e4c322d00e936",
  measurementId: "G-SLE97FHM7P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);