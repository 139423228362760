import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footer4.css'
import { Link } from 'react-router-dom'

const Footer4 = (props) => {
  return (
    <footer className="footer4-footer7 thq-section-padding">
      <div className="footer4-max-width thq-section-max-width">
       
        <div className="footer4-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer4-row">
            <div className="footer4-container">
              <span className="thq-body-small">© 2024 FlarEdge</span>
            </div>
            <div className="footer4-footer-links">
              <span>
                <Link to="/PrivacyPolicy">
                  <Fragment>
                    <span className="footer4-text6 thq-body-small">
                      Privacy Policy
                    </span>
                  </Fragment>
                  </Link>
              </span>
              <span>
                <Link to="/TandC">
                  <Fragment>
                    <span className="footer4-text7 thq-body-small">
                      Terms and Conditions
                    </span>
                  </Fragment>
                  </Link>
              </span>
              <span>
                {props.cookiesLink ?? (
                  <Fragment>
                    <span className="footer4-text5 thq-body-small">
                      Cookies Policy
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer4.defaultProps = {
  cookiesLink: undefined,
  logoSrc: '/img/logo.png',
  logoAlt: 'FlarEdge Logo',
  privacyLink: undefined,
  termsLink: undefined,
}

Footer4.propTypes = {
  cookiesLink: PropTypes.element,
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  privacyLink: PropTypes.element,
  termsLink: PropTypes.element,
}

export default Footer4
