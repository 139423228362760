import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial17.css'

const Testimonial17 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial17-max-width thq-section-max-width">
        <div className="testimonial17-container10">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="testimonial17-text37 thq-heading-2 text-start">
                  Testimonials
                </h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.content1 ?? (
              <Fragment>
                <span className="testimonial17-text31 thq-body-small ">
                  FlarEdge sessions have been instrumental in providing valuable
                  insights to our students. The professionals are highly
                  knowledgeable and engaging.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container12">
                 
                  <div className="testimonial17-container13">
                    <strong>
                      {props.author1Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text26 thq-body-large text-start">
                          Anjali Verma
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author1Position ?? (
                        <Fragment>
                          <span className="testimonial17-text32 thq-body-small text-start">
                          12th Grade Student (Engineering Aspirant)
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review1 ?? (
                    <Fragment>
                      <span className="testimonial17-text35 thq-body-small text-start">
                      FlarEdge has been a game-changer for me! The one-on-one mentorship sessions helped me gain clarity on my career path. My mentor was patient, knowledgeable, and always there to guide me. Thanks to FlarEdge, I feel confident and well-prepared for my engineering entrance exams
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card2"
              >
                <div className="testimonial17-container14">
                 
                  <div className="testimonial17-container15">
                    <strong>
                      {props.author2Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text33 thq-body-large text-start">
                          Rohan Patel
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author2Position ?? (
                        <Fragment>
                          <span className="testimonial17-text27 thq-body-small text-start">
                          Class 11 Student
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review2 ?? (
                    <Fragment>
                      <span className="testimonial17-text24 thq-body-small text-start">
                      The personalized career counseling I received through FlarEdge was exactly what I needed to focus on my goals. They not only helped me figure out which career suits me best but also gave me strategies to study smarter, not harder. I’m so grateful for the advice and encouragement
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card3"
              >
                <div className="testimonial17-container16">
                
                  <div className="testimonial17-container17">
                    <strong>
                      {props.author3Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text29 thq-body-large text-start">
                          Aditya Rao
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author3Position ?? (
                        <Fragment>
                          <span className="testimonial17-text25 thq-body-small text-start">
                          Medical Entrance Exam Candidate                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review3 ?? (
                    <Fragment>
                      <span className="testimonial17-text30 thq-body-small text-start">
                      FlarEdge connected me with mentors who had first-hand experience cracking medical entrance exams. Their insights into preparation strategies and time management were incredible! They also helped me deal with the stress and pressure of exams. I’m so grateful for their support
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card4"
              >
                <div className="testimonial17-container18">
                 
                  <div className="testimonial17-container19">
                    <strong>
                      {props.author4Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text34 thq-body-large text-start">
                          Sana Khan
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author4Position ?? (
                        <Fragment>
                          <span className="testimonial17-text28 thq-body-small text-start">
                          Government Exam Aspirant                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review4 ?? (
                    <Fragment>
                      <span className="testimonial17-text36 thq-body-small text-start">
                      FlarEdge helped me create a structured study plan that kept me motivated and on track. The mentors were always encouraging, offering practical tips and emotional support
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial17.defaultProps = {
  author2Src:'img/testimonials/author2.png',
  author3Src:'img/testimonials/author3.png',
  review2: undefined,
  author2Alt: 'Img of Jane Smith',
  author3Position: undefined,
  author1Name: undefined,
  author1Alt: 'Img of John Doe',
  author2Position: undefined,
  author1Src: 'img/testimonials/author1.png',
  author4Src:'img/testimonials/author4.png',
  author4Position: undefined,
  author3Name: undefined,
  author3Alt: 'Img of Michael Johnson',
  review3: undefined,
  content1: undefined,
  author1Position: undefined,
  author2Name: undefined,
  author4Alt: 'Img of Sarah Lee',
  author4Name: undefined,
  review1: undefined,
  review4: undefined,
  heading1: undefined,
}

Testimonial17.propTypes = {
  author2Src: PropTypes.string,
  author3Src: PropTypes.string,
  review2: PropTypes.element,
  author2Alt: PropTypes.string,
  author3Position: PropTypes.element,
  author1Name: PropTypes.element,
  author1Alt: PropTypes.string,
  author2Position: PropTypes.element,
  author1Src: PropTypes.string,
  author4Src: PropTypes.string,
  author4Position: PropTypes.element,
  author3Name: PropTypes.element,
  author3Alt: PropTypes.string,
  review3: PropTypes.element,
  content1: PropTypes.element,
  author1Position: PropTypes.element,
  author2Name: PropTypes.element,
  author4Alt: PropTypes.string,
  author4Name: PropTypes.element,
  review1: PropTypes.element,
  review4: PropTypes.element,
  heading1: PropTypes.element,
}

export default Testimonial17
