import Navbar from '../components/navbar8';
import Footer from '../components/footer4';
import { Helmet } from "react-helmet";

const Services = ()  => {
  return (
    <div className="home">
       <Helmet>
        <title>FlarEdge | Sevices</title>
        <meta name="description" content="FlarEdge provides personalized mentorship and career advice." />
        <link rel="canonical" href="https://flaredge.online/Services" />
      </Helmet>
     <Navbar/>
       <h1>welcome to our service page</h1>
    <Footer/>
    </div>
  );
}

export default Services;