import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Home from './views/Home';
import Login from './views/Login';
import Mentors from './views/Mentors';
import Services from './views/services';
import Signup from './views/Signup';
import TandC from'./views/tandc';
import PrivacyPolicy from './views/privacypolicy';
import Update from './views/update';
import Member from './views/Member';


const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Services" element={<Services/>} />
          <Route path="/Mentors" element={<Mentors />} />
          <Route path="/TandC" element={<TandC />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/update/:userId" element={<Update />} />
          <Route path="/Mentors/:userId" element={<Member />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
