import Navbar from '../components/navbar8';
import { Helmet } from "react-helmet";

import Footer from '../components/footer4';
const TandC = ()  => {
  return (
    <>
     <Helmet>
        <title>FlarEdge | Terms and Conditions</title>
        <meta name="description" content="FlarEdge provides personalized mentorship and career advice." />
        <link rel="canonical" href="https://flaredge.online/TandC" />
      </Helmet>
    <Navbar />
    <div className="container my-4 mx-5 text-start">
      <h1>Terms and Conditions</h1>
      <p>
        These Terms and Conditions apply to mentors who wish to provide guidance
        and support to students through our platform. By joining Flaredge as a
        mentor, you agree to comply with these Terms.
      </p>

      <h2>1. Scope of Services</h2>
      <p>
        As a mentor, you agree to provide career guidance, advice, and
        mentorship to students in accordance with the values and objectives of
        Flaredge. Your role involves offering insights, sharing experiences, and
        providing constructive feedback to help students make informed decisions
        about their future careers.
      </p>

      <div className="py-3"></div>

      <h2>2. Mentor Responsibilities</h2>
      <ol>
        <li>
          <strong>Professional Conduct:</strong> You agree to maintain a high
          standard of professionalism, integrity, and respect in all
          interactions with students, parents, and Flaredge staff.
        </li>
        <li>
          <strong>Confidentiality:</strong> You must keep all personal
          information of students confidential and not disclose it to any third
          parties, except as required by law or with express consent from the
          student and Flaredge.
        </li>
        <li>
          <strong>Accurate Representation:</strong> You agree to provide
          accurate, honest, and unbiased information and advice based on your
          personal knowledge and experience.
        </li>
        <li>
          <strong>Commitment to Schedule:</strong> You are expected to adhere to
          the scheduled sessions with students and provide advance notice to
          Flaredge in case of any changes or cancellations.
        </li>
        <li>
          <strong>Goodies:</strong> Submit the properties of Flaredge as and
          when instructed. If anything gets misplaced or damaged, compensation
          must be paid from the mentor's side.
        </li>
        <li>
          <strong>Submissions:</strong> A PowerPoint presentation about the
          session must be uploaded by the mentor 24 hours before the scheduled
          session and approved by the Flaredge team.
        </li>
        <li>
          <strong>Compliance with Laws and Policies:</strong> You agree to
          comply with all applicable laws, regulations, and Flaredge policies
          while providing mentorship services.
        </li>
      </ol>

      <div className="py-3"></div>

      <h2>3. Intellectual Property</h2>
      <p>
        All materials, content, and resources provided to you by Flaredge are
        the intellectual property of Flaredge. You may use these materials
        solely for the purpose of providing mentorship services and agree not to
        distribute, reproduce, or share them without prior written consent from
        Flaredge.
      </p>

      <div className="py-3"></div>

      <h2>4. Compensation and Payment Terms</h2>
      <ol>
        <li>
          <strong>Compensation:</strong> The compensation for your services as a
          mentor will be determined by Flaredge and communicated to you in
          writing. Payment terms, including rates and schedules, will be agreed
          upon separately.
        </li>
        <li>
          <strong>Payment Disputes:</strong> Any disputes regarding payments
          must be raised within 30 days of receipt of payment. Flaredge will
          work in good faith to resolve any disputes promptly.
        </li>
      </ol>

      <div className="py-3"></div>

      <h2>5. Termination</h2>
      <ol>
        <li>
          <strong>Termination by Flaredge:</strong> Flaredge reserves the right
          to terminate your mentor agreement at any time for any reason,
          including but not limited to breaches of these Terms, misconduct, or
          unsatisfactory performance.
        </li>
        <li>
          <strong>Termination by Mentor:</strong> You may terminate your
          agreement with Flaredge by providing 14 days' written notice. Any
          outstanding commitments to students must be fulfilled before
          termination.
        </li>
        <li>
          <strong>Effect of Termination:</strong> Upon termination, you must
          cease all use of Flaredge’s materials and return any confidential
          information or resources to Flaredge.
        </li>
      </ol>

      <div className="py-3"></div>

      <h2>6. Liability and Indemnification</h2>
      <ol>
        <li>
          <strong>Limitation of Liability:</strong> Flaredge is not liable for
          any indirect, incidental, or consequential damages arising out of or
          related to your role as a mentor. Flaredge’s total liability is
          limited to the amount paid to you for your services in the 12 months
          preceding the incident.
        </li>
        <li>
          <strong>Indemnification:</strong> You agree to indemnify and hold
          harmless Flaredge, its affiliates, and their respective officers,
          directors, employees, and agents from any claims, liabilities,
          damages, losses, or expenses arising out of your breach of these Terms
          or your negligent or wrongful conduct.
        </li>
      </ol>

      <div className="py-3"></div>

      <h2>7. Confidentiality</h2>
      <p>
        Both parties agree to keep all non-public information disclosed during
        the mentorship confidential and not use it for any purpose other than as
        permitted under these Terms.
      </p>

      <div className="py-3"></div>

      <h2>8. Amendments</h2>
      <p>
        Flaredge reserves the right to amend these Terms at any time. Notice of
        any changes will be provided to you, and continued participation as a
        mentor after such notice constitutes acceptance of the revised Terms.
      </p>

      <div className="py-3"></div>

      <h2>9. Governing Law</h2>
      <p>
        These Terms and Conditions are governed by the laws of India. Any
        disputes arising under these Terms will be resolved in accordance with
        the jurisdiction of India.
      </p>

      <div className="py-3"></div>

      <h2>10. Agreement to Terms</h2>
      <p>
        By joining Flaredge as a mentor, you acknowledge that you have read,
        understood, and agree to be bound by these Terms and Conditions.
      </p>
    </div>    <Footer />

    </>
  )}
export default TandC;