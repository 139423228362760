import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Card from './Home_c/card';

const Mentor_hero = () => {
  const [mentors, setMentors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Mentors'));
        const mentorData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setMentors(mentorData);
      } catch (err) {
        console.error("Error fetching mentors: ", err);
        setError("Failed to load mentors.");
      } finally {
        setLoading(false);
      }
    };

    fetchMentors();
  }, []);

  const handleProfileClick = (userId) => {
    navigate(`/Mentors/${userId}`);
  };

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  if (error) {
    return <div>{error}</div>; // Error state
  }

  return (
    <div className="Mentors">
      <div className="page-title mt-5" data-aos="fade">
        <div className="heading">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-8">
                <h1 className="mb-0">
                  Looking For Mentors, Influencers <br /> check these out?
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="trainers" className="section trainers">
        <div className="container">
          <div className="row gy-1">
            {mentors.map(profile => (
              <div
                className="col-lg-3 member"
                onClick={() => handleProfileClick(profile.userId)}
                data-aos="fade-up"
                data-aos-delay={profile.id * 100}
                key={profile.id}
              >
                <Card profile={profile} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Mentor_hero;
