import Navbar from '../components/navbar8';
import Footer from '../components/footer4';
import { Helmet } from "react-helmet";

const PrivacyandPolicy = ()  => {
  return (
    <>
     <Helmet>
        <title>FlarEdge | Privacy </title>
        <meta name="description" content="FlarEdge provides personalized mentorship and career advice." />
        <link rel="canonical" href="https://flaredge.online/PrivacyPolicy" />
      </Helmet>
    <Navbar />
    <div className="container my-4 mx-5 text-start">
      <h1>Privacy Policy</h1>
      
      <h2> Consent</h2>
      <ol>
        <li>We may obtain your consent to collect and use certain types of Personal Data when we are required to do so by law.</li>
        <li>
          Once consent is obtained from the individual to use his or her information for those purposes, Intrnforte has the individual’s implied consent to collect or receive any supplementary information that is necessary to fulfil the same purposes.
          <ul type="a">
            <li>Express consent will also be obtained if, or when, a new use is identified.</li>
          </ul>
        </li>
        <li>
          Consent may also be implied where a user is given notice and a reasonable opportunity to opt-out of his or her personal information being used for mail-outs, the marketing of new services or products, and the client, customer, or member does not opt-out.
        </li>
        <li>
          Subject to certain exceptions, individuals can withhold or withdraw their consent for Intrnforte to use their personal information in certain ways.
          <ul type="a">
            <li>The personal information is necessary to provide the service or product.</li>
            <li>The withdrawal of consent would frustrate the performance of a legal obligation.</li>
          </ul>
        </li>
        <li>
          By using this website, acknowledging this privacy policy, or by voluntarily providing us with your Personal Data, you consent to the collection, storage, and processing of your Personal Data in accordance with this privacy policy and our Terms of Use.
        </li>
        <li>
          If you refuse or withdraw your consent, or if you choose not to provide us with any required Personal Data, we may not be able to provide you the services that can be offered on our Platform.
        </li>
      </ol>

      <div className="py-4"></div> {/* Padding between sections */}

      <h2> Third-Party Services</h2>
      <ol>
        <li>
          In general, the third-party providers used by us will only collect, use, and disclose your information to the extent necessary to allow them to perform the services they provide to us.
        </li>
        <li>
          Certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies.
          <ul type="a">
            <li>For these providers, we recommend that you read their privacy policies to understand how your personal information will be handled.</li>
          </ul>
        </li>
        <li>
          Once you leave our Website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy.
        </li>
        <li>
          While Intrnforte may make efforts to ensure that third parties use personal data only for the purposes for which it was disclosed, these third parties are independent, and Intrnforte exercises no control over their actions.
        </li>
      </ol>

      <div className="py-4"></div> {/* Padding between sections */}

      <h2> Security Measures and Communication</h2>
      <ol>
        <li>
          Intrnforte has implemented security features designed to prevent the loss, misuse, alteration, or unauthorized release of or access to personal information provided to us.
        </li>
        <li>
          However, the confidentiality of any communication transmitted to or from Intrnforte via this Site or e-mail cannot be guaranteed.
        </li>
        <li>
          The technical processing and transmission of the Site content may involve:
          <ul type="a">
            <li>Transmissions over various networks.</li>
            <li>Changes to conform to technical requirements of connecting networks or devices.</li>
          </ul>
        </li>
        <li>
          Accordingly, Intrnforte is not responsible for the security of information transmitted via the Internet.
        </li>
      </ol>

      <div className="py-4"></div> {/* Padding between sections */}

      <h2> No Guarantees</h2>
      <ol>
        <li>
          While this Privacy Policy states our standards for maintenance of data, we are not in a position to guarantee these standards.
        </li>
        <li>
          There may be factors beyond our control that could result in disclosure of data.
        </li>
        <li>
          As a consequence, we disclaim any warranties or representations relating to maintenance or non-disclosure of data.
        </li>
      </ol>

      <div className="py-4"></div> {/* Padding between sections */}

      <h2> Right to Change Privacy Statement</h2>
      <ol>
        <li>Intrnforte may change this Privacy Statement at any time.</li>
        <li>
          It is the obligation of users returning to the Site to learn of changes to the Privacy Statement since their last visit.
        </li>
        <li>
          Any change to this Privacy Statement shall be effective as to any visitor who has visited the Site before the change was made.
        </li>
      </ol>
    </div>
    <Footer />

    </>
  )}
export default PrivacyandPolicy;