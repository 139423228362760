import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar8';
import { auth } from '../firebase'; // Adjust the path according to your file structure
import "./Signup.css";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Get the access token
      const token = await user.getIdToken();
      localStorage.setItem('token', token); // Save token to local storage
      navigate(`/Mentors/${userCredential.user.uid}`); // Redirect to personal page

      
      
    }
    
    catch (err) {
      setError(err.message);
    }
    
  };

  return (
    <>
      <Helmet>
        <title>FlarEdge | Login</title>
        <meta name="description" content="FlarEdge provides personalized mentorship and career advice." />
        <link rel="canonical" href="https://flaredge.online/Login" />
      </Helmet>
      <Navbar />
      <section className="h-100 bg-dark">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col">
              <div className="card card-registration my-4">
                <div className="row g-0">
                  <div className="col-xl-6 d-none d-xl-block">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp"
                      alt="Sample img"
                      className="img-fluid"
                      style={{ borderTopLeftRadius: '.25rem', borderBottomLeftRadius: '.25rem' }}
                    />
                  </div>
                  <div className="col-xl-6">
                    <div className="card-body p-md-5 text-black">
                      <h3 className="mb-5 text-uppercase">Login to your account</h3>

                      {error && <div className="alert alert-danger">{error}</div>}

                      <div data-mdb-input-init className="form-outline mb-5">
                        <input
                          type="email"
                          id="form3Example97"
                          className="form-control form-control-lg"
                          placeholder='Email'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div data-mdb-input-init className="form-outline mb-5">
                        <input
                          type="password"
                          id="form3Example98"
                          className="form-control form-control-lg"
                          placeholder='Password'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div className="d-flex justify-content-end pt-3">
                        <button
                          type="button"
                          data-mdb-button-init
                          data-mdb-ripple-init
                          className="btn submit btn-lg ms-2"
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
